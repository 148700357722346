import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class PrintService {
	/**
	 * Constructor.
	 */
	constructor(private router: Router) {}

	printWithoutStyles() {
		const printWindow = window.open('', '_blank', 'width=800,height=600')!;
		this.router.navigate(['/print-view']).then(() => {
			printWindow.document.write(
				'<html><head><title>Contrato</title></head><body>',
			);
			printWindow.document.write(
				document.getElementsByTagName('app-print-view')[0].innerHTML,
			);
			printWindow.document.write('</body></html>');
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		});
	}
}

