<!-- drawer component -->

<aside id="sidebar"
	class="absolute top-0 left-0 z-50 flex-col flex-shrink-0 w-64 h-full font-normal duration-75 lg:flex transition-width transition-transform -translate-x-full">
	<div
		class="relative h-full flex flex-col flex-1 min-h-0 pt-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
		<div class="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
			<div class="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
				<h5 id="sidebar-label" class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">
					Menu
				</h5>
				<button type="button" data-drawer-hide="sidebar" aria-controls="sidebar"
					class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
					<svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd"></path>
					</svg>
					<span class="sr-only">Close menu</span>
				</button>
				<ul class="pb-2 space-y-2">
					<li *ngFor="let route of options">
						<ng-container *ngIf="route.childs.length > 0; else elseTemplate">
							<button [attr.aria-controls]="route.dropdown" [attr.data-collapse-toggle]="route.dropdown"
								(click)="route.collapse = !route.collapse" type="button"
								class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700">

								<ion-icon [name]="route.icon" slot="start" ></ion-icon>

								<ion-label class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>
									{{ route.name }}
								</ion-label>
								<svg sidebar-toggle-item="sidebar-toggle-item" class="w-6 h-6" fill="currentColor"
									viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd"></path>
								</svg>
							</button>
							<ul [class.hidden]="!route.collapse" [id]="route.dropdown" class="py-2 space-y-2">
								<li *ngFor="let child of route.childs">
									<a sidebar-toggle-item data-drawer-hide="sidebar" aria-controls="sidebar"
										[routerLink]="child.route"
										class="flex items-center p-2 text-base text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700">
										{{ child.name }}
									</a>
								</li>
							</ul>
						</ng-container>
						<ng-template #elseTemplate>
							<a sidebar-toggle-item data-drawer-hide="sidebar" aria-controls="sidebar"
								[routerLink]="route.route"
								class="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
								<ion-icon [name]="route.icon" slot="start" ></ion-icon>
								<ion-label class="ml-3" sidebar-toggle-item>{{ route.name }}</ion-label>
							</a>
						</ng-template>
					</li>
					<li>
						<a sidebar-toggle-item data-drawer-hide="sidebar" aria-controls="sidebar"
							[routerLink]="'/company/ratings'"
							class="flex sm:hidden items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
							<ion-icon [name]="'star-outline'" slot="start" ></ion-icon>
							<!-- Aquí se muestra el HTML del icono -->
							<span class="ml-3" sidebar-toggle-item> Valoraciones </span>
						</a>
					</li>
					
				</ul>
			</div>
		</div>
		<div class="absolute bottom-0 left-0 justify-center w-full p-4 space-x-4 bg-white flex dark:bg-gray-800"
			sidebar-bottom-menu="sidebar-bottom-menu">
			<div class="pt-2 space-y-2">
				<a (click)="logOut()"
					class="flex items-center cursor-pointer p-2 text-base text-gray-900 transition duration-75 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
					<svg class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
						fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.51428 20H4.51428C3.40971 20 2.51428 19.1046 2.51428 18V6C2.51428 4.89543 3.40971 4 4.51428 4H8.51428V6H4.51428V18H8.51428V20Z"
							fill="currentColor"></path>
						<path
							d="M13.8418 17.385L15.262 15.9768L11.3428 12.0242L20.4857 12.0242C21.038 12.0242 21.4857 11.5765 21.4857 11.0242C21.4857 10.4719 21.038 10.0242 20.4857 10.0242L11.3236 10.0242L15.304 6.0774L13.8958 4.6572L7.5049 10.9941L13.8418 17.385Z"
							fill="currentColor"></path>
					</svg>
					<span class="ml-3" sidebar-toggle-item="sidebar-toggle-item">
						Cerrar Sesión
					</span>
				</a>
			</div>
		</div>
	</div>
</aside>

<div class="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" id="sidebarBackdrop"></div>