import 'moment-timezone';

import moment from 'moment';

Date.prototype.showLocalDatetime = function (): Date {
  // Convierte la fecha UTC a Moment.js
  const momentDate = moment.utc(this);

  // Obtén la zona horaria del cliente
  const clientTimezone = moment.tz.guess();

  // Convierte la fecha a la zona horaria del cliente
  const localDatetime = momentDate.tz(clientTimezone).toDate();

  return localDatetime;
};

Date.prototype.getTimeAgo = function (): string {
  const now = new Date();
  const diff = Math.abs(now.getTime() - this.getTime()) / 1000; // Difference in seconds

  if (diff < 60) {
    return 'a few moments ago';
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    // Format the date for more than 24 hours ago
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(this);
  }
};
