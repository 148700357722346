import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Freight } from '@app/core/models/freight.model';
import { FreightService } from '@app/core/services/freight/freight.service';
import { LocalTimePipe } from '../../../../shared/pipes/local-time.pipe';

@Component({
	selector: 'app-freight-history',
	templateUrl: './freight-history.component.html',
	styleUrls: ['./freight-history.component.scss'],
	standalone: true,
	imports: [CommonModule, LocalTimePipe, DatePipe],
})
export class FreightHistoryComponent implements OnInit {
	freight: Freight | null | undefined = undefined;
	open: boolean = false;
	constructor(
		private sanitizer: DomSanitizer,
		private freightService: FreightService,
	) {}

	ngOnInit() {
		this.freightService.freightHistory$.subscribe({
			next: (res) => {
				this.open = res.open;
				this.freight = res.freight;
			},
		});
	}

	closeHistory() {
		// this.open = !this.open;
		this.freightService.closeFreightHistory();
	}
	getSanitizedHtml(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}

