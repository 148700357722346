<svg
  [attr.width]="width"
  [attr.height]="height"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 103.08 86.73"
>
  <defs>
    <style>
      .cls-1 {
        stroke-width: 4px;
      }
      .cls-2 {
        isolation: isolate;
      }

      .cls-3 {
        mix-blend-mode: multiply;
      }
    </style>
  </defs>
  <g class="cls-2">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1" class="cls-3">
        <g>
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M57.86,68.66h7.39c3.86,0,3.87-6,0-6h-7.39c-3.86,0-3.87,6,0,6h0Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M18.39,62.66h-9.32l3,3c-.22-7.03-.47-14.06-.49-21.09-.01-3.86-6.01-3.87-6,0,.02,7.03.27,14.06.49,21.09.05,1.62,1.33,3,3,3h9.32c3.86,0,3.87-6,0-6h0Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M2.9,6h56.83l-3-3v62.66l3-3h-19.62c-3.86,0-3.87,6,0,6h19.62c1.62,0,3-1.37,3-3V3c0-1.62-1.37-3-3-3H2.9C-.96,0-.97,6,2.9,6h0Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M6.79,28.58h24.53c3.86,0,3.87-6,0-6H6.79c-3.86,0-3.87,6,0,6h0Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M64.25,37.84h30.4c3.86,0,3.87-6,0-6h-30.4c-3.86,0-3.87,6,0,6h0Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M89.76,68.66h9.9c1.62,0,3-1.37,3-3v-18.4c0-4.47,1.28-11.09-.61-15.29-2.85-6.34-7.39-12.34-10.96-18.3-1.31-2.2-2.46-4.81-4.07-6.8-1.49-1.84-2.86-1.72-5.04-1.72h-22.26c-3.86,0-3.87,6,0,6h24.56l-2.59-1.49c2.65,4.42,5.29,8.84,7.94,13.26l5.3,8.86c.51.85.96,1.9,1.6,2.67,1.02,1.23.02.29.12-.47-.66,5.41,0,11.31,0,16.76v14.93l3-3h-9.9c-3.86,0-3.87,6,0,6h0Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M29.98,86.73c-8.44,0-15.3-6.87-15.3-15.31s6.87-15.3,15.3-15.3,15.31,6.87,15.31,15.3-6.87,15.31-15.31,15.31ZM29.98,63.12c-4.58,0-8.3,3.73-8.3,8.3s3.73,8.31,8.3,8.31,8.31-3.73,8.31-8.31-3.73-8.3-8.31-8.3Z"
          />
          <path
            [attr.fill]="
              color != null || color != undefined ? color : '#4B4B4B'
            "
            class="cls-1"
            d="M78.16,86.73c-8.44,0-15.3-6.87-15.3-15.31s6.87-15.3,15.3-15.3,15.31,6.87,15.31,15.3-6.87,15.31-15.31,15.31ZM78.16,63.12c-4.58,0-8.3,3.73-8.3,8.3s3.73,8.31,8.3,8.31,8.31-3.73,8.31-8.31-3.73-8.3-8.31-8.3Z"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
