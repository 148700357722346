import { Component, Input } from '@angular/core';
import { IonTabBar, IonTabs } from '@ionic/angular/standalone';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { businessOutline, search } from 'ionicons/icons';

import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { CommonModule } from '@angular/common';
import { FreightIconComponent } from '../../../../shared/components/icons/freight-icon/freight-icon.component';
import { HomeIconComponent } from '../../../../shared/components/icons/home-icon/home-icon.component';
import { IonicModule } from '@ionic/angular';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { ROLES as Roles } from '@app/core/utils/roles.enums';
import { TabsAdminComponent } from './components/tabs-admin/tabs-admin.component';
import { TabsCarrierComponent } from './components/tabs-carrier/tabs-carrier.component';
import { TabsCompanyComponent } from './components/tabs-company/tabs-company.component';
import { UserIconComponent } from '../../../../shared/components/icons/user-icon/user-icon.component';
import { addIcons } from 'ionicons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    UserIconComponent,
    FreightIconComponent,
    HomeIconComponent,
    TabsAdminComponent,
    TabsCarrierComponent,
    TabsCompanyComponent,
    IonTabBar,
    IonTabs,
  ],
})
export class FooterComponent {
  role!: Roles;
  newNotifications: number = 0;
  roles_enums = Roles;
  /**
   * Constructor.
   */
  constructor(
    private authService: AuthenticationService,
    private _notificationService: NotificationService,
  ) {
    this.role = this.authService.userRole;
  }

  ngOnInit() {
    addIcons({ businessOutline, search });
    this.invokeReceiveNotification();
  }

  invokeReceiveNotification() {
    this._notificationService
      .onEvent('_ReceiveNotification')
      .subscribe((notification) => {
        this.updateNotificationsNumber();
      });
  }

  private updateNotificationsNumber() {
    this.newNotifications++;
  }
}
