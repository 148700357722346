import { AuthGuard } from './auth/auth.guard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionGuard } from './permission/permission.guard';

@NgModule({
  providers:[
    PermissionGuard,
    AuthGuard
  ]
})
export class GuardsModule { }
