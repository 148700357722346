import { AuthUtils } from "../utils/auth.utils";

interface MenuOptionInterface {
    id: number;
    name: string;
    route: string;
    icon: string;
    childs: MenuOption[];
    collapse: boolean;
    get dropdown(): string;
}

export class MenuOption implements MenuOptionInterface {
    id: number;
    name: string;
    route: string;
    icon: string;
    childs: MenuOption[];
    collapse: boolean;
    get dropdown(): string {
        return this.name.toLowerCase() + '-' + this.id;
    }

    constructor(id: number, name: string, route: string, icon: string, childs: MenuOption[], collapse: boolean = false) {
        this.id = id;
        this.name = name;
        this.route = route;
        this.icon = icon;
        this.childs = childs;
        this.collapse = collapse;
    }


}
