import { Component, HostListener, Pipe } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import {
	IonApp,
	IonButton,
	IonContent,
	IonFabList,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonRouterOutlet,
	IonSelect,
	IonSelectOption,
	IonSplitPane,
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonTitle,
	IonToggle,
	IonToolbar,
	Platform,
} from '@ionic/angular/standalone';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { FreightHistoryComponent } from './components/freight-history/freight-history.component';
import { LayoutService } from './services/layout.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { initFlowbite } from 'flowbite';

@Component({
	selector: 'app-layout',
	standalone: true,
	imports: [
		CommonModule,
		RouterOutlet,
		IonApp,
		IonRouterOutlet,
		NavbarComponent,
		FooterComponent,
		IonHeader,
		IonContent,
		IonToolbar,
		IonTitle,
		IonMenuToggle,
		IonMenu,
		IonListHeader,
		IonIcon,
		IonLabel,
		IonItem,
		IonList,
		IonToggle,
		RouterLink,
		FormsModule,
		IonSplitPane,
		IonTabs,
		IonTabBar,
		IonTabButton,
		SidebarComponent,
		IonButton,
		IonFabList,
		IonSelect,
		IonSelectOption,
		FreightHistoryComponent,
	],
	templateUrl: './layout.component.html',
	styleUrl: './layout.component.scss',
})
export class LayoutComponent {
	loggedIn = true;
	showNavbar: boolean = true;
	private unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private platform: Platform,
		private _notificationService: NotificationService,
		private layoutService: LayoutService,
	) {}

	ngOnInit() {
		initFlowbite();
		this._notificationService.startConnection();
		this.layoutService.navbar$.pipe(takeUntil(this.unsubscribe)).subscribe({
			next: (show) => {
				this.showNavbar = show;
			},
		});
	}
}

