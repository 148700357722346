import { CurrencyPipe, DatePipe } from '@angular/common';

import { AuthenticationService } from './authentication/authentication.service';
import { CatalogService } from './catalog/catalog.service';
import { CompanyService } from './company/company.service';
import { ContractService } from './contract/contract.service';
import { FileService } from './file/file.service';
import { FreightService } from './freight/freight.service';
import { GoogleMapsService } from './google-maps/google-maps.service';
import { HomeService } from './home/home.service';
import { LocalTimePipe } from '@app/shared/pipes/local-time.pipe';
import { NgModule } from '@angular/core';
import { NotificationService } from './notification/notification.service';
import { PdfService } from './pdf/pdf.service';
import { PermissionService } from './permission/permission.service';
import { PrintService } from './print/print.service';
import { RatingsService } from './ratings/ratings.service';
import { RecordService } from './record/record.service';
import { ReportService } from './report/report.service';
import { SignalRService } from './signalR/signal-r.service';
import { SwalService } from './swal/swal.service';
import { TabService } from './tab/tab.service';
import { ToastService } from './toast/toast.service';
import { TruckService } from './truck/truck.service';
import { UserService } from './user/user.service';

@NgModule({
	providers: [
		AuthenticationService,
		PermissionService,
		RecordService,
		FileService,
		GoogleMapsService,
		FreightService,
		CatalogService,
		CompanyService,
		SwalService,
		HomeService,
		UserService,
		NotificationService,
		SignalRService,
		RatingsService,
		ContractService,
		ToastService,
		TruckService,
		TabService,
		PrintService,
		PdfService,
		DatePipe,
		LocalTimePipe,
		CurrencyPipe,
		ReportService,
	],
})
export class ServicesModule {}
