<svg
  [attr.width]="width"
  [attr.height]="height"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 92.18 89.81"
>
  <defs>
    <style>
      .cls-1 {
        isolation: isolate;
      }

      .cls-2 {
        stroke-miterlimit: 10;
        stroke-width: 4px;
      }

      .cls-3 {
        mix-blend-mode: multiply;
      }
    </style>
  </defs>
  <g class="cls-1">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1" class="cls-3">
        <path
          class="cls-2"
          [attr.fill]="color != null || color != undefined ? color : '#4B4B4B'"
          [attr.stroke]="color != null || color != undefined ? color : '#4B4B4B'"
          d="M1.51,60.5c0-5.72.03-11.45-.01-17.17-.02-3.45,1.27-6.28,3.86-8.64C16.09,24.86,26.79,14.99,37.49,5.14c5.28-4.86,11.92-4.85,17.22.03,10.65,9.8,21.29,19.61,31.97,29.38,2.68,2.45,4.01,5.35,3.99,8.94-.04,11.41-.01,22.81-.02,34.22,0,6.38-4.34,10.59-10.91,10.6-6.24,0-12.47,0-18.71,0-1.92,0-2.4-.46-2.4-2.32,0-8.43,0-16.86,0-25.29,0-4.1-1.98-6.03-6.21-6.03-4.37,0-8.74,0-13.11,0-3.65,0-5.79,2.06-5.8,5.61-.01,8.51,0,17.01,0,25.52,0,2.1-.42,2.5-2.57,2.5-6.2,0-12.39,0-18.59,0-6.5,0-10.85-4.23-10.86-10.53,0-5.76,0-11.52,0-17.28ZM61.98,85.06c.46,0,.77,0,1.08,0,5.68,0,11.36.01,17.04,0,4.26-.01,7.21-2.87,7.22-6.99.02-11.68,0-23.36,0-35.03,0-2.25-.82-4.15-2.5-5.69-10.97-10.09-21.93-20.19-32.93-30.26-3.44-3.15-8.28-3.1-11.71.05-10.89,10-21.77,20.02-32.67,30.02-1.81,1.66-2.7,3.64-2.69,6.07.02,11.52,0,23.05.01,34.57,0,4.45,2.86,7.25,7.42,7.26,5.6.01,11.2,0,16.8,0,.34,0,.69-.04,1.11-.06,0-.54,0-1,0-1.46,0-7.77-.02-15.55,0-23.32.02-5.27,3.67-8.78,9.08-8.79,4.45,0,8.9,0,13.35,0,5.81,0,9.35,3.44,9.36,9.1,0,7.66,0,15.31,0,22.97v1.56Z"
        />
      </g>
    </g>
  </g>
</svg>
