import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { IonIcon, IonLabel } from '@ionic/angular/standalone';
import { Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { Roles } from '@app/shared/utils/roles.utils';
import { MenuOption as RouteModel } from '@app/core/models/route.model';
import { addIcons } from 'ionicons';
import { homeOutline } from 'ionicons/icons';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	standalone: true,
	imports: [CommonModule, NgFor, RouterLink, IonLabel, IonIcon],
})
export class SidebarComponent implements OnInit {
	private optionsUnsubscribe = new Subject<void>();
	options: RouteModel[] = [];
	roles_enums = Roles;

	public role: number = 0;

	constructor(
		private _router: Router,
		private _authService: AuthenticationService,
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.getOptions();
		this.subscribeEventToReloadMenu();
		this.role = parseInt(this._authService.currentUser.role);
	}

	private subscribeEventToReloadMenu() {
		this._authService.reloadMenu$.subscribe({
			next: () => {
				this.getOptions();
			},
		});
	}

	getOptions() {
		this._authService
			.getMenu()
			.pipe(takeUntil(this.optionsUnsubscribe))
			.subscribe((response) => {
				this.options = response.map(
					(a) => new RouteModel(a.id, a.name, a.route, a.icon, a.childs, false),
				);
			});
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.optionsUnsubscribe.next();
		this.optionsUnsubscribe.complete();
		this.optionsUnsubscribe.unsubscribe();
	}

	ngAfterContentInit(): void {}

	async logOut() {
		const token = await this._authService.getFcmToken();
		this._authService.signOut(token).subscribe({
			next: () => {
				localStorage.removeItem('access_token');
				this._router.navigateByUrl('/auth/login');
			},
			error: () => {
				localStorage.removeItem('access_token');
				this._router.navigateByUrl('/auth/login');
			},
		});
	}
}

