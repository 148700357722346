<!-- Footer -->
<!-- class="flex flex-row w-full bottom-0 absolute bg-white border-t-1"> -->

<ion-tab-bar slot="bottom">
  <app-tabs-admin
    [notificationNumber]="newNotifications"
    *ngIf="role == roles_enums.ADMIN"
  />

  <app-tabs-carrier
    [notificationNumber]="newNotifications"
    *ngIf="role == roles_enums.CARRIER"
  />

  <app-tabs-company
    [notificationNumber]="newNotifications"
    *ngIf="role == roles_enums.COMPANY"
  />
</ion-tab-bar>
