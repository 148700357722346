import * as allIcons from 'ionicons/icons';

import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { StatusBar, Style } from '@capacitor/status-bar';

import { Component } from '@angular/core';
import { LoadScriptService } from './core/services/load-script/load-script.service';
import { NotificationService } from './core/services/notification/notification.service';
import { RouterOutlet } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { addIcons } from 'ionicons';
import { initFlowbite } from 'flowbite';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [IonApp, IonRouterOutlet, SharedModule, RouterOutlet],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent {
	title = ' ';

	/**
	 * Constructor.
	 */
	constructor(
		private LoadScript: LoadScriptService,
		private platform: Platform,
		private notificationService: NotificationService,
	) {
		addIcons(allIcons);
	}

	ngOnInit(): void {
		initFlowbite();
		this.LoadScript.loadAllScriptsFromJson();

		if (this.platform.is('hybrid')) {
			this.setColorStatusBar();
		}

		this.notificationService.startConnection();
	}
	private setColorStatusBar() {
		StatusBar.setBackgroundColor({
			color: '#ffffff',
		}).then((res) => {});
		StatusBar.setStyle({ style: Style.Default });
	}
}

