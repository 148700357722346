import { a as patchEsm, b as bootstrapLazy } from './core-12de052e.js';
const defineCustomElements = (win, options) => {
  return patchEsm().then(() => {
    bootstrapLazy([["hive-color-popover_3", [[1, "hive-rich-text", {
      "fontSmoothing": [4, "font-smoothing"],
      "options": [16],
      "currentStates": [32],
      "colorOpen": [32],
      "highlightOpen": [32],
      "linkPopoverOpen": [32],
      "focused": [32],
      "getContent": [64],
      "setContent": [64],
      "hiveFocus": [64]
    }], [1, "hive-color-popover", {
      "colors": [16],
      "position": [1],
      "isOpen": [4, "is-open"],
      "allowCustomColor": [4, "allow-custom-color"]
    }], [1, "hive-link-popover", {
      "url": [1],
      "text": [1],
      "creating": [4]
    }]]]], options);
  });
};
export { defineCustomElements };