import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'home-icon',
  templateUrl: './home-icon.component.html',
  styleUrls: ['./home-icon.component.scss'],
  standalone: true,
})
export class HomeIconComponent implements OnInit {
  @Input() color: string | undefined;
  @Input() width: string = '14';
  @Input() height: string = '15';
  constructor() {}

  ngOnInit() {}
}
