import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  of,
  switchMap,
} from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class PermissionService {
  private routes: any = null;
  private URL: string = environment.apiUrl + 'permissions/';
  private inProgressRequests = new Set<string>();
  private pendingRequestsSubject = new BehaviorSubject<boolean>(false);
  private validatedPermissions: { [route: string]: boolean } = {};
  public loading$: Subject<boolean> = new Subject<boolean>();
  /**
   * Constructor
   * @param authService Auth Service
   */
  constructor(
    private authService: AuthenticationService,
    private _httpClient: HttpClient,
    private _router: Router
  ) {}

  /**
   * Gets dashboards
   * @returns
   */
  get routesPermissions(): string[] {
    this.routes = JSON.parse(
      localStorage.getItem('routes_permissions') ?? '[]'
    );
    return this.routes;
  }

  set routesPermissions(routes: string[]) {
    localStorage.setItem('routes_permissions', JSON.stringify(routes));
  }

  hasValidatedPermission(route: string): boolean {
    return this.validatedPermissions[route] || false;
  }

  setValidatedPermission(route: string, value: boolean) {
    this.validatedPermissions[route] = value;
  }

  hasPermission(route: string): Observable<boolean> {
    if (this.hasValidatedPermission(route)) return of(true);
    this.loading$.next(true);
    return this._httpClient
      .post(this.URL + 'has-route-permission', { route })
      .pipe(
        switchMap((response: any) => {
          this.loading$.next(false);
          const res = response as ResponseModel<boolean>;
          return of(res.data ?? false);
        }),
        catchError(() => {
          this.loading$.next(false);
          return of(false);
        })
      );
  }

  hasAccess(claimType: string, value: string): Observable<boolean> {
    return this._httpClient
      .post(this.URL + 'has-access', {
        type: claimType,
        value: value,
      })
      .pipe(
        switchMap((response: any) => {
          const res = response as ResponseModel<boolean>;
          return of(res.data);
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  get pendingRequests$(): Observable<boolean> {
    return this.pendingRequestsSubject.asObservable();
  }

  private updatePendingRequestsState() {
    this.pendingRequestsSubject.next(this.inProgressRequests.size > 0);
  }

  private addRequestToInProgress(route: string) {
    this.inProgressRequests.add(route);
    this.updatePendingRequestsState();
  }

  private removeRequestFromInProgress(route: string) {
    this.inProgressRequests.delete(route);
    this.updatePendingRequestsState();
  }
}
