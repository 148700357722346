<ion-app class="flex flex-col w-full h-full relative">
  <app-navbar class="flex w-full relative" *ngIf="showNavbar"/>
  <app-freight-history class="absolute z-50"/>
  <app-sidebar class="absolute z-50 h-full" />
  <ion-content #content class="flex h-full no-scrollbar" style="overflow: scroll !important; overscroll-behavior: none;">
    <div id="main-content" class="flex w-full max-w-7xl h-full mx-auto no-scrollbar" style="overscroll-behavior: none;">
      <div class="flex flex-col w-full no-scrollbar" style="overscroll-behavior: none;">
        <router-outlet style="overscroll-behavior: none;" />
      </div>
    </div>
  </ion-content>
  <app-footer class="md:hidden"></app-footer>
</ion-app>
