import { Component, Input, OnInit } from '@angular/core';
import { IonIcon, IonTabButton } from '@ionic/angular/standalone';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { businessOutline, notificationsOutline } from 'ionicons/icons';

import { CommonModule } from '@angular/common';
import { HomeIconComponent } from '../../../../../../shared/components/icons/home-icon/home-icon.component';
import { NotificationBadgeComponent } from "../../../../../../shared/components/icons/notification-badge/notification-badge.component";
import { UserIconComponent } from '../../../../../../shared/components/icons/user-icon/user-icon.component';
import { addIcons } from 'ionicons';

@Component({
    selector: 'app-tabs-admin',
    templateUrl: './tabs-admin.component.html',
    styleUrls: ['./tabs-admin.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IonTabButton,
        RouterLink,
        RouterLinkActive,
        HomeIconComponent,
        IonIcon,
        UserIconComponent,
        NotificationBadgeComponent
    ]
})
export class TabsAdminComponent implements OnInit {
  @Input() notificationNumber: number = 4;
  constructor() {}

  ngOnInit() {
    addIcons({ notificationsOutline, businessOutline });
  }

  resetBadge(){
    this.notificationNumber = 0;
  }
}
