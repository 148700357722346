export enum Roles {
    ADMIN = 1,
    CARRIER = 2,
    COMPANY = 3,
    // TRAINER = 2,
    // MEMBER = 3,
  }
  export function getRoleByNumber(roleNumber: number): Roles | null {
    const roleValues = Object.values(Roles);
    if (roleValues.includes(roleNumber)) {
      return roleNumber as Roles;
    }
    return null;
  }
  