import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadScriptService {
	constructor(private http: HttpClient) {}

	getJsonFileName(): Observable<any> {
		return this.http.get<any>('assets/js/scripts.json');
	}

	loadScript(name: string): Promise<any> {
		// return new Promise((resolve, reject) => {});
		return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `assets/js/${name}`;

			script.onload = () => {
				resolve({ script: name, loaded: true, status: 'Loaded' });
			};

			script.onerror = (error: any) => {
				resolve({ script: name, loaded: false, status: 'Loaded' });
			};
			setTimeout(function () {
				document.getElementsByTagName('head')[0].appendChild(script);
			}, 3000); // 8000 milisegundos (8 segundos)
		});
	}

	// Esta función carga todos los scripts obtenidos del archivo JSON
	loadAllScriptsFromJson(): void {
		this.getJsonFileName().subscribe((data) => {
			if (data && data.script && Array.isArray(data.script)) {
				data.script.forEach((scriptName: string) => {
					this.loadScript(scriptName).then((result) => {
						// Puedes manejar el resultado aquí según tu requerimiento
					});
				});
			}
		});
	}
}
