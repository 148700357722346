import { GuardsModule } from './guards/guards.module';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ServicesModule } from './services/services.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './utils/auth.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { DomSanitizer } from '@angular/platform-browser';
import { initFlowbite } from 'flowbite/lib/esm/components';

@NgModule({
  imports: [
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        throwNoTokenError: false,
        tokenGetter: getToken,
        allowedDomains: getAllowedDomains(),
      },
    }),
    ServicesModule,
    GuardsModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    initFlowbite();
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import this module in the AppModule only.'
      );
    }
  }
}

export function getAllowedDomains(): (string | RegExp)[] {
  return [/./];
}

export function getToken() {
  return localStorage.getItem('token');
}
