<div
	[ngClass]="{
    'relative transition-colors ease-in-out duration-20': open,
		'hidden transition-colors ease-in-out duration-20': !open
	}"
	class="relative z-10"
	aria-labelledby="slide-over-title"
	role="dialog"
	aria-modal="true"
>
	<div
		class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
		aria-hidden="true"
	></div>

	<div class="fixed inset-0 overflow-hidden">
		<div class="absolute inset-0 overflow-hidden">
			<div
				class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
			>
				<div class="pointer-events-auto w-screen max-w-md">
					<div
						class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
					>
						<div class="flex-0 px-4">
							<div class="flex items-center justify-between">
								<h2
									class="text-xl font-bold text-gray-900"
									id="slide-over-title"
								>
									Historial de Cambios
								</h2>
								<div class="ml-3 flex h-7 items-center">
									<button
										(click)="closeHistory()"
										type="button"
										class="relative -m-2 p-2 text-primary-400 hover:text-primary-500"
									>
										<span class="absolute -inset-0.5"></span>
										<span class="sr-only">Close panel</span>
										<svg
											class="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											aria-hidden="true"
											data-slot="icon"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M6 18 18 6M6 6l12 12"
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>

						<div
							class="border-t border-gray-200 px-4 py-6 sm:px-6 overflow-y-auto"
						>
							<ol
								class="relative border-s border-gray-200 dark:border-gray-700"
							>
								@for (history of freight?.histories; track history) {
									<li class="mb-10 ms-6">
										<span
											class="absolute flex items-center justify-center w-6 h-6 bg-primary-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-primary-900"
										>
											<svg
												class="w-2.5 h-2.5 text-primary-800 dark:text-primary-300"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												viewBox="0 0 20 20"
											>
												<path
													d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
												/>
											</svg>
										</span>
										<h3
											class="flex items-center mb-1 text-lg font-medium text-gray-900 dark:text-white"
										>
											{{ history.title }}
											@if (history.latest) {
												<span
													class="bg-primary-100 text-primary-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300 ms-3"
												>
													Último
												</span>
											}
										</h3>
										<time
											class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
										>
											{{
												history.creationDate
													| localTime
													| date: 'dd/MM/yyy HH:mm'
											}}
										</time>
										<p
											[innerHTML]="getSanitizedHtml(history.content)"
											class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400"
										></p>
									</li>
								}
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

