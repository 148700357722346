import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
	IonicRouteStrategy,
	provideIonicAngular,
} from '@ionic/angular/standalone';
import {
	PreloadAllModules,
	RouteReuseStrategy,
	provideRouter,
	withPreloading,
	withViewTransitions,
} from '@angular/router';
import { provideHttpClient, withFetch } from '@angular/common/http';

import { CoreModule } from './core/core.module';
import { LOCALE_ID } from '@angular/core';
import localeMx from '@angular/common/locales/es-MX';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { registerLocaleData } from '@angular/common';
import { routes } from './app.routes';

// Registrar el locale de México
// registerLocaleData(localeMx, 'es-MX');

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(
			routes,
			withPreloading(PreloadAllModules),
			withViewTransitions(),
		),
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		provideHttpClient(withFetch()),
		importProvidersFrom(CoreModule),
		provideIonicAngular(),
		provideRouter(routes),
		provideAnimationsAsync(),
		// { provide: LOCALE_ID, useValue: 'es-MX' }, // Configura el LOCALE_ID para es-MX
	],
};
