import {
	ActivatedRouteSnapshot,
	CanActivate,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree,
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {
	/**
	 * Constructor
	 */
	constructor(
		@Inject(AuthenticationService) private _authService: AuthenticationService,
		private _router: Router,
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Check the authenticated status
	 *
	 * @param redirectURL
	 * @private
	 */
	private _check(redirectURL: string): Observable<boolean> {
		// Check the authentication status
		return this._authService.check(redirectURL).pipe(
			switchMap((authenticated: boolean) => {
				// If the user is not authenticated...

				if (!authenticated) {
					// Redirect to the sign-in page
					// localStorage.removeItem('access_token');
					this._router.navigate(['auth/login'], {
						queryParams: { redirectURL },
					});
					this._authService.redirectURL = redirectURL;
					// Prevent the access
					return of(false);
					// Si el token guardado en el localStorage ya expiro te llevara a la vista login.
				} else {
					return of(true);
				}
			}),
		);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Can activate
	 *
	 * @param route
	 * @param state
	 */
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url;

		return this._check(redirectUrl);
	}

	/**
	 * Can activate child
	 *
	 * @param childRoute
	 * @param state
	 */
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
		return this._check(redirectUrl);
	}

	/**
	 * Can load
	 *
	 * @param route
	 * @param segments
	 */
	canLoad(
		route: Route,
		segments: UrlSegment[],
	): Observable<boolean> | Promise<boolean> | boolean {
		return this._check('/');
	}
}
