<ion-tab-button
  class="flex flex-col m-auto justify-center items-center w-full mx-2 shrink-0 truncate"
  [routerLink]="'/home'"
  [routerLinkActive]="'active-tab'"
>
  <home-icon
    color="var(--ui-accent)"
    [width]="'22'"
    [height]="'22'"
    class="my-[2px]"
  />
  Inicio
</ion-tab-button>

<ion-tab-button
  class="flex flex-col m-auto justify-center items-center w-full mx-2 shrink-0 truncate"
  [routerLink]="'/freights/search'"
  [routerLinkActive]="'active-tab'"
>
  <ion-icon name="search" color="accent" />
  Buscar
</ion-tab-button>

<ion-tab-button
  class="flex flex-col m-auto justify-center items-center w-full mx-2 shrink-0 truncate"
  [routerLink]="'/freights/dispatch-me'"
  [routerLinkActive]="'active-tab'"
>
  <freight-icon
    color="var(--ui-accent)"
    [width]="'22'"
    [height]="'22'"
    class="my-[2px]"
  />

  Cargas
</ion-tab-button>

<ion-tab-button
  class="flex flex-col m-auto justify-center items-center w-full mx-2 shrink-0 truncate"
  [routerLink]="'/notifications'"
  [routerLinkActive]="'active-tab'"
  (click)="resetBadge()"
>
  <notification-badge [notificationNumber]="notificationNumber" />
  <ion-icon name="notifications-outline" />

  Notificaciones
</ion-tab-button>

<ion-tab-button
  class="flex flex-col m-auto justify-center items-center w-full mx-2 shrink-0 truncate"
  [routerLink]="'/profile'"
  [routerLinkActive]="'active-tab'"
>
  <user-icon
    color="var(--ui-accent)"
    [width]="'22'"
    [height]="'22'"
    class="my-[2px]"
  />
  Perfil
</ion-tab-button>
