import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { PermissionService } from '../../services/permission/permission.service';

@Injectable()
export class PermissionGuard implements CanActivateChild {
  /**
   *
   */
  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private authService: AuthenticationService,
    private _activatedroute: ActivatedRoute
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const permissionRoute = state.url; // Use the route URL as the identifier for the permission
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this.permissionService.hasPermission(permissionRoute).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          this.permissionService.setValidatedPermission(permissionRoute, true);
          return of(hasPermission); // Allow navigation
        } else {
          const newUrl = this.router.parseUrl(redirectUrl);
          this.authService.redirectURL = redirectUrl;
          return of(hasPermission);
        }
      }),
      catchError(() => of(false)) // Handle errors
    );
  }
}
