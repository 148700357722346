import { Component, Input, OnInit } from '@angular/core';
import { IonIcon, IonTabButton } from '@ionic/angular/standalone';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { notificationsOutline, search } from 'ionicons/icons';

import { FreightIconComponent } from '@app/shared/components/icons/freight-icon/freight-icon.component';
import { HomeIconComponent } from '@app/shared/components/icons/home-icon/home-icon.component';
import { NgIf } from '@angular/common';
import { NotificationBadgeComponent } from "../../../../../../shared/components/icons/notification-badge/notification-badge.component";
import { NotificationService } from '@app/core/services/notification/notification.service';
import { UserIconComponent } from '@app/shared/components/icons/user-icon/user-icon.component';
import { addIcons } from 'ionicons';

@Component({
    selector: 'app-tabs-company',
    templateUrl: './tabs-company.component.html',
    styleUrls: ['./tabs-company.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonTabButton,
        RouterLink,
        RouterLinkActive,
        HomeIconComponent,
        IonIcon,
        FreightIconComponent,
        UserIconComponent,
        NotificationBadgeComponent
    ]
})
export class TabsCompanyComponent implements OnInit {
  @Input() notificationNumber: number = 3;
  constructor() {}

  ngOnInit() {
    addIcons({ search, notificationsOutline });
  }

  resetBadge(){
    this.notificationNumber = 0;
  }
}
