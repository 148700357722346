import '@app/shared/utils/extensions/date.extensions';
import '@app/shared/utils/extensions/date.extensions';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';
import { defineCustomElements } from '@teamhive/rich-text-editor/loader';

defineCustomElements(window);
bootstrapApplication(AppComponent, appConfig).catch((err) =>
	console.error(err),
);
